`<template>
  <div class="home">
    <div class="logoContent">
      <!-- <img class="logo" src="../assets/logo-wl.png"> -->
      <div class="title">娇果园区块链服务平台</div>
    </div>
    <div class="content">
      <div class="inputTitle">所在区域</div>
      <div class="input">{{data.block}}</div>
      <div class="inputTitle">唯一编码</div>
      <div class="input">{{data.unicode}}</div>
      <div class="inputTitle">成块时间</div>
      <div class="input">{{data.blockDate}}</div>
      <div class="inputTitle">所在链</div>
      <div class="input">{{data.chain}}</div>
      <div class="bottomIcon">
        <div>
          <div class="inputTitle">上传者身份</div>
          <div class="inputTitle">上传主题证明</div>
          <div class="inputTitle">上传时间</div>
        </div>
        <div class="icon">
          <!-- <img src="../assets/icon-wl.png" alt="" style="width:90px"> -->
          <img src="../assets/icon-km.png" alt="" style="width:90px">
          <div class="signature">
            <div>{{data.createName}}</div>
            <div style="margin-top: 10px;">{{data.createDate}}</div>
          </div>
        </div>
      </div>
      <div v-if="data.pdfFile" class="inputTitle" @click="imgClick(data.pdfFile)">
        <span>附件</span><p style="color:deepblue">点击预览PDF</p>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'certificate',
  components: {
  },
  data(){
    return{
      data: Object
    }
  },
  created(){
    this.init()
     console.log(this.$route.params.data)
  },
  methods:{
    init(){
      let data = this.$route.params.data
      if(data.isFake && data.isFake==true){
        this.data={
          block:"87e4f795c1cd0ef9afe389cabaf73ab8aac12dd1213c0f1979806512ddfc6a0f",
          unicode:"Fjelbq3mu6f2",
          blockDate:"2021-07-13 11:30:24",
          chain:"d104b5a0f2554584889920c069b3786a",
          createName:"",
          createDate:"2021-07-13 14:22:17",
          pdfFile:'https://wanlintrace.oss-cn-hangzhou.aliyuncs.com/resource/newFile/test/20DGPC0035T.pdf'
        }
      }else{
        this.$axios.get('/show/cert?batchId='+localStorage.getItem('batchId')+'&traceId='+data.traceId).then(res=>{
          this.data = res.data.retData
        })
      }
    },
    imgClick(e) {
      console.log(e);
      location.href = e;
      window.location.href=e
    },
  }
}
</script>
<style scoped>
.home{
  height: calc(100vh - 16px);
  background-image: url('../assets/back.png');
  background-size: 100% 100%;
}
.logoContent{
  text-align: center;
}
.logo{
  width: 56px;
  margin-top: 7px;
}
.title{
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0775F1;
  margin-top: 10px;
}
.content{
    padding: 20px 27px;
}
.inputTitle{
  font-weight: 400;
  color: #999999;
  font-size: 12px;
  margin-top: 10px;
}
.input{
  background: rgba(7, 117, 241, 0.06);
  border-radius: 5px;
  text-align: left;
  padding: 7px 30px;
  margin-top: 7px;
  word-break: break-all;
}
.icon{
  position: relative;
  top: -31px;
}
.bottomIcon{
  display: flex;
  justify-content: space-between;
  color: #999999;
  font-size: 12px ;
}
.signature{
  position: absolute;
  bottom: -12px;
  text-align: right;
  right: 18px;
}
</style>